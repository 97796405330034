// colors
$blueColor: lightblue;
$redColor: #e66565;
// $blueColor: #ceade6; // halloween theme turns purple
// $redColor: #ec9729; // halloween theme turns orange
$greyColor: lightgrey;
$greenColor: lightgreen;
$maintenanceColor: orange;
$white: white;
// breakpoints
$breakpoint-phone: 576px;
// fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');

@import './word';
@import './app';
@import './dark';

//external styles
@import "../../node_modules/react-loader-spinner/dist/loader/css/react-spinner-loader.css";
@import "../../node_modules/react-toggle/style.css";
