body {
  font-family: "Roboto Slab", Arial, serif !important;
  overflow-x: hidden;
}
.background-svg-img {
  position: fixed;
  display: contents;
  max-width: 100vw;
  svg {
    position: fixed;
    height: 100%;
    width: 100%;
    transform: scale(5.0);
    transform: rotate(12deg);
    fill: #000;
    opacity: .02;
  }
}

.seo-description {
  color: transparent;
  margin-top: -14px;
  font-size: .2em;
}

.header {
  width: 100vw;
  top: 0px;
  min-height: 40px;
  margin-bottom: 8px;
  background: #eee;
  color: $greyColor;
  a {
    margin: 8px 0px !important;
    color: #333;
  }

  .play-now-link {
    text-align: right;
  }

  h3,
  h4,
  h5 {
    margin: 16px 0px;
  }
}

.playguide-img {
  max-height: 100%;
  max-width: 100%;
  border: 2px solid $greyColor;
  margin: 20px 0px;
  border-radius: 8px;
}

.maintenance-alert {
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0px;
  min-height: 40px;
  padding: 0px 22px;
  background: $maintenanceColor;
  color: #222;
  z-index: 100;

  h3, h4, h5 {
    position: relative;
    margin: 16px 32px 16px 0px;
  }
}

.game-header {
  background-color: #e1e1e1;
  font-size: 2em;
  transition: 100ms;

  &.red-team {
    background-color: $redColor;
    .phase-container {
      .phase-title {
        &.prev, &.next {
          color: darken($redColor, 30%);
        }
      }
      .phase-dot {
        // background-color: darken($redColor, 10%);
        // border: black 1px solid;
        color: darken($redColor, 30%);
      }
    }
  }
  &.blue-team {
    background-color: $blueColor;
    .phase-container {
      .phase-title {
        &.prev, &.next {
          color: darken($blueColor, 30%);
        }
      }
      .phase-dot {
        // background-color: darken($blueColor, 10%);
        // border: black 1px solid;
        color: darken($blueColor, 30%);
      }
    }
  }
  button {
    height: 60%;
    margin-top: 15px;
    background: rgba($white, 0.2);
    font-weight: 600;
    &:hover {
      background-color: rgba($white, 0.3);
    }
  }

  .bonus-time {
    position: absolute;
    right: 0px;
    margin-right: 72px;
    margin-top: -10px;
    font-size: 0.3em;
    opacity: 0.6;
  }

  h3, h4, h5 {
    margin: 8px;
  }

  .timer-and-helpToggle {
    margin-right: 40px;
  }

  .phase-container {
    .phase-dot {
      // width: 8px;
      // height: 8px;
      position: absolute;
      bottom: -10px;
      padding: 3px 10px;
      border-radius: 20px;
      font-size: .3em;
      font-weight: 600;
      // background: $white;
      &.center {
        left: calc(50% - 30px);
      }
      &.next {
        left: calc(50% - 26px);
      } 
      &.prev {
        left: calc(50% - 25px);
      }
    }
  }
}


.pages-container {
  min-height: calc(100vh - 220px);

  .support-graphic {
    position: relative;
    display: inline-block;
    height: 100px;

    img {
      max-width: 100%;
      max-height: 100%;
      margin: 10px;
    }
  }

  .support-text {
    position: relative;
    display: inline-block;
    margin: 16px 0px;

    em {
      font-size: .8em;
      opacity: 0.2;
    }

    .upper {
      margin-bottom: 4px;
    }
    
    .lower {
      margin-top: 4px;
      overflow-wrap: anywhere;
    }
    
  }

  .support-tier-block {
    margin-bottom: 60px;
  }
  
  .membership-title {
    position: relative;
    margin-top: 24px;
    margin-bottom: -70px;
    padding: 0px 12px;
    h3 {
      z-index: 10;
      text-align: center !important;
    }
  }
  
  .membership-banner-graphic {
    position: relative;
    display: inline-block;
    margin: 2px 0px 12px 0px;
    height: 70px;
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      margin-top: -132px;
      opacity: .3;
      z-index: -1;

      &.robin{

      }
    }
  }

  .supporter-person-container {
    display: inline-flex;
    width: min-content;
    margin: 3px;
    padding: 6px 18px;
    border-radius: 25px;
    background: $greyColor;
    color: #222;
    .supporter-person-name {
      color: #222;
    }
  }

}

.invisible-option {
  width: 100%;
  height: 30px;
  background: transparent !important;
  background-color: transparent !important;
  border: none !important;
  cursor: default;
  &:hover {
    background: transparent !important;
  }
}

.recent-games-list-container {
  margin-left: 16px;
  margin-right: 16px;
}

.pages-row-item {
  margin-bottom: 34px;
  h4 {
    margin-bottom: 6px;
  }


  &.recent-game-row {
    margin-bottom: 6px;
    padding-bottom: 6px;
    &:nth-child(2n+1) {
      background: rgba(#efefef, .5);
    }

    .through-dash {
      margin-top: -10px;
    }
    
    .end-date {
      margin-top: 4px;
    }

    .theme-count {
      margin-top: 0px;
      text-align: center;;
    }

    .tb-pad {
      padding-top: 20px;
      padding-bottom: 12px;
    }
    .theme-name, .mode-name {
      display: inline-block;
      margin: 1px;
      padding: 1px 4px;
      border-radius: 2px;
      background: #dfdfdf;
      font-size: .8em;
    }
    .theme-name {
      font-size: .7em;
    }

    .show-hide-sets-btn {
      padding: 2px 8px;
      background: #dfdfdf;
      border: 2px solid #c7c7c7;
      border-radius: 4px;
      cursor: pointer;
      font-size: .9em;
      font-style: italic;
      text-align: center;
    }
  }
}

.lobby-container {
  .above-fold {
    min-height: 100vh;
  }
  .header-banner {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    padding: 3px 10px;
    margin: 0px;
    background-color: #ffbb4c;
    color: #161616;
    text-align: center;
    a {
      color: inherit;
    }
  }

  .theme-toggle-btn {
    padding: 4px 10px;
    border: 2px solid lighten(lightgrey, 10%);
    background: white;
    
    &.available-theme {
      border: 2px solid solid lighten(lightgrey, 10%);
      background: lighten(lightgrey, 10%);
    }

    label {
      width: auto; 
      position: absolute;
      left: 24px;
    }
  }
  .theme-toggle-text {
  }
  .new-theme {
    position: absolute;
    top: 12px;
    left: 48px;
    // width: 6px;
    // height: 6px;
    padding: 2px 4px;
    background: $greenColor;
    border-radius: 2px;
    font-size: .5em;
    font-style: italic;
    opacity: .5;
  }
  .theme-set-word-count {
    margin: 0px 4px;
    font-size: .7em;
    opacity: .5;
  }
  .theme-difficulty {
    position: absolute;
    right: 16px;
    padding: 2px 4px;
    margin: 0px 4px;
    font-weight: bold;
    border-radius: 2px;
    font-size: .8em;
    color:rgba(#000, .5);

    &.easy {
      background: rgba(lightgreen, 0.2)
    }
    &.medium {
      background: rgba(yellow, 0.3)
    }
    &.hard {
      background: rgba(orange, 0.2)
    }
    &.pro {
      background: rgba(#ec3f0b, 0.3)
    }
  }
}

.donut-chart-container {
  height: 80px;
  padding: 8px;
  &.small {
    height: 40px;
    padding: 4px;
  }
}

.footer {
  width: 100%;
  bottom: 0px;
  min-height: 120px;
  margin-top: 60px;
  padding: 16px;
  background: #333;
  color: $greyColor;
  a {
    margin: 8px 0px !important;
    color: $greyColor;
  }

  h3,
  h4,
  h5 {
    margin: 8px;
  }

  .mini-margin {
    margin-left: 12px;
    margin-right: 12px;
  }


  .dark-mode-toggle-container {
    position: relative;
    display: inline-block;
    width: auto;
    height: auto;
    font-size: 1.4em;
  }
}

.emoji-text {
  font-size: 2em;
}

.game-footer {
  position: fixed;
  width: 100%;
  height: 40px;
  bottom: 0px;
  background-color: rgba($greyColor, 0.9);
  z-index: 12;
  h3,
  h4,
  h5 {
    margin: 8px;
  }
}

.game-option {
  h3, h2, h4, h5 {
    margin-bottom: 4px;
    margin-top: 50px;
    &.sub-title {
      margin-top: 6px;
    }
  }
}

.featured-game-mode {    
  border: 2px solid rgba(orange, 0.2);;
  border-radius: 3px;
  background-color: rgba(orange, 0.1);
  margin-bottom: 4px;
  &::after {
    position: absolute; 
    right: 0px;
    top: 0px;
    content: 'FEATURED';
    background-color: rgba(orange, 0.2);
    padding: 2px 4px;
    border-radius: 1px;
    font-style: italic;
    font-size: .5em;

  }
}

.game-option-title {
  display: inline-block;
  margin-top: 8px !important;
  margin-left: 8px;
  margin-bottom: 0px;
  font-weight: 600;
}

.game-option-description {
  margin-top: 4px;
  font-size: .8em;
}

.one-hundo {
  width: 100%;
}

.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.sub-title {
  margin-top: -16px;
}
.bomb-sub-title {
  margin-top: 0px;
  margin-bottom: 0px;
}

.opposing-team-word-list {
  // background: #c2c2c2;
  font-size: 0.8em;

  .word-button {
    // background: rgba($white, 1);
    // border: rgba($white, .3) solid 3px;
    // &.red-team {
    //     background-color: $redColor;

    // }
    // &.blue-team {
    //     background-color: $blueColor;
    // }

    // &.active {
    //     background: rgba($white, .7);
    // }
  }

  &.red-team {
    background-color: $redColor;
  }
  &.blue-team {
    background-color: $blueColor;
  }
}

.inline {
  display: inline-block;
  margin-right: 8px;
}

.instruction-block {
  background: #eee;

  svg {
    position: relative;
    font-size: 30px;
    margin-top: 20px;
  }
  p {
    margin: 10px 0px;
  }
}
// .footer {

// }

button {
  padding: 8px;
  margin: 4px;
  text-align: center;
  border: rgba($greyColor, 0.4) solid 2px;
  border-radius: 5px;
  cursor: pointer;
  transition: 200ms;
  outline: none;
  background: rgba($greyColor, 0.3);
  font-family: inherit;
  &:hover {
    background-color: rgba($greyColor, 0.6);
  }
  &.huge {
    min-height: 130px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    padding: 20px 20px;
    font-size: 2em;
  }
  &.not-huge {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    padding: 20px 10px;
    font-size: 1em;
  }
  &.green {
    background: rgba($greenColor, 0.3);
    &:hover {
      background-color: rgba($greenColor, 0.6);
    }
  }
  &.white {
    background: rgba($white, 0.7);
    color: black;
    &:hover {
      background-color: rgba($white, 0.8);
    }
  }
  &.help-button {
    position: absolute;
    top: 0px;
  }

  &.new-round-btn {
    position: absolute;
    top: 0;
    margin-left: -110px;
  }
}

.vert-spacer {
  &.vert-spacer {
    margin-top: 70px;
  }
}

input, textarea {
  outline: none;
  border: none;
  border-bottom: $greyColor 3px solid;
  text-align: center;
  font-size: 1.6em;
  background: transparent;

  &:active,
  &:focus {
    border-bottom: darken($greyColor, 20%) 3px solid;
  }
  &.huge {
    width: 100%;
    min-height: 50px;
    font-size: 3em;
  }
}

textarea {
  resize: none;
  text-align: left;
  font-size: 1.3em;
}

.xs-helper-header {
  margin-top: 10px;
}
.xs-mobile-keyboard-helper {
  margin-top: -18px;
  font-size: .75em;
}

.team-select-buttons {
  .red-team {
    border-color: rgba($redColor, 1);
    background: rgba($white, 0.1);

    &:hover {
      background: rgba($redColor, 0.4);
      border-color: $redColor;
    }
  }
  .blue-team {
    border-color: rgba($blueColor, 1);
    background: rgba($white, 0.1);

    &:hover {
      background: rgba($blueColor, 0.4);
      border-color: $blueColor;
    }
  }
}

.game-container {
  min-height: 100vh;
  width: 100%;
  &.red-team {
    // background: $redColor;
  }
  &.blue-team {
    // background: $blueColor;
  }
}

.slide-in-message {
  position: absolute;
  right: -50px;
  top: 0px;
  padding: 12px 70px 12px 20px;
  background: #ededed;
  border-radius: 50px;

  &.how-to-play-link {
    margin-top: 100px;
  }
  &.video-recommendation {
    margin-top: 28px;
  }
  &.anniversary-note {
    background: $blueColor;
    left: -50px;
    right: unset;
    padding: 12px 20px 12px 70px;
    text-align: right;
    margin-top: 28px;
  }
  &.game-results-feedback-link {
    position: fixed;
    top: unset;
    bottom: 0px;
    margin-top: 0px;
    margin-bottom: 50px;
    z-index: 9000;
  }
}

.game-results-container {
  padding-bottom: 30px;

  .descriptor-row {
      position: relative;
      padding: 20px 0px;
    &.game {
      margin-bottom:  -100px;
      top: 20px;
    }
    &.round {
        top: 54px;
        margin-top: -20px;
        margin-bottom: -44px;
        background: rgba($greyColor, .2);
        z-index: 10;
        cursor: pointer;
    }
  }
  .score-number {
    padding: 20px;
    margin-top: 16px;
    font-size: 3.3em;
    text-align: center;
    font-weight: 900;
    border-radius: 12px;
    min-width: 50px;
    z-index: 12;
    // &::before {
    //     content: "";
    //     position: absolute;
    //     height: 2px;
    //     width: 100%;
    //     top: 73px;
    //     left: 0px;
    //     background: $white;
    // }
    &:hover {
      .bomb-score-explanation, 
      .sacrifice-score-explanation {
        opacity: .6;
      }
    }

    &.loading {
      opacity: .7;
    }
    &.for-round {
      padding: 16px;
      font-size: 2.6em;
      min-width: 40px;
      // &.blue-team {
      //   color: $blueColor;
      // }
      // &.red-team {
      //   color: $redColor;
      // }
      .sacrifice-score-explanation {
        margin: 48px 0px 0px 9px;
      }
    }
    &:not(.for-round) {
      &.blue-team {
        background: $blueColor;
      }
      &.red-team {
        background: $redColor;
      }
    }

    .bomb-score-explanation {
      position: absolute;
      margin: -3px 0px 0px 12px;
      font-size: 12px;
      opacity: .15;
      transition: 200ms;
    }
    .sacrifice-score-explanation {
      position: absolute;
      margin: 58px 0px 0px 14px;
      font-size: 12px;
      opacity: .15;
      transition: 200ms;
    }
  }

  .teams-guess-phase-bw {
      // height: 100%;
      // background: rgba($greyColor, 0.2);
      // &.red-team {
      //     background: rgba($redColor, 0.1);
      // }
      // &.blue-team {
      //     background: rgba($blueColor, 0.1);
      // }
  }

  .teams-guess-phase-tw {
    // height: 100%;
    background: rgba($greyColor, 0.2);
    // &.red-team {
    //     background: rgba($redColor, 0.1);
    // }
    // &.blue-team {
    //     background: rgba($blueColor, 0.1);
    // }
  }

  .word-button {
    &.small {
      font-size: 1.2em;
    }
  }
}

.join-text {
  color: rgba(#000, .6);
  .text-center  {
    margin-bottom: 0px;
  }
  .game-mode-line {
    position: absolute;
    margin-top: 85px;
    margin-bottom: 3px;
    top: 0;
    width: calc(100% - 32px);
    font-weight: 800;
    opacity: 0;
    .game-mode-name {
      margin-bottom: 1px;
    }
    &:nth-of-type(1) {
      // color: green;
      -webkit-animation: fadeInOut 24s infinite 0s; /* Safari 4+ */
      -moz-animation:    fadeInOut 24s infinite 0s; /* Fx 5+ */
      -o-animation:      fadeInOut 24s infinite 0s; /* Opera 12+ */
      animation:         fadeInOut 24s infinite 0s; 
    }
    &:nth-of-type(2) {
      // color: blue;
      -webkit-animation: fadeInOut 24s infinite 6s; /* Safari 4+ */
      -moz-animation:    fadeInOut 24s infinite 6s; /* Fx 5+ */
      -o-animation:      fadeInOut 24s infinite 6s; /* Opera 12+ */
      animation:         fadeInOut 24s infinite 6s; 
    }
    &:nth-of-type(3) {
      // color: red;
      -webkit-animation: fadeInOut 24s infinite 12s; /* Safari 4+ */
      -moz-animation:    fadeInOut 24s infinite 12; /* Fx 5+ */
      -o-animation:      fadeInOut 24s infinite 12s; /* Opera 12+ */
      animation:         fadeInOut 24s infinite 12s; 
    }
    &:nth-of-type(4) {
      // color: white;
      -webkit-animation: fadeInOut 24s infinite 18s; /* Safari 4+ */
      -moz-animation:    fadeInOut 24s infinite 18s; /* Fx 5+ */
      -o-animation:      fadeInOut 24s infinite 18s; /* Opera 12+ */
      animation:         fadeInOut 24s infinite 18s; 
    }
    &:nth-of-type(5) {
      // color: yellow;
      -webkit-animation: fadeInOut 24s infinite 24s; /* Safari 4+ */
      -moz-animation:    fadeInOut 24s infinite 24s; /* Fx 5+ */
      -o-animation:      fadeInOut 24s infinite 24s; /* Opera 12+ */
      animation:         fadeInOut 24s infinite 24s; 
    }
  }
  .game-modes-text {
    font-weight: 300;
    margin-top: 6px;
    margin-bottom: 6px;
  }
}

.join-instructions-modal {
  position: fixed;
  margin: 8vh 12vw;
  width: 70vw;
  padding: 16px;
  background: $greyColor;
  border-radius: 12px;
  border: 6px solid rgba(#000, 0.1);
  color: rgba(#000, .6);
  text-align: center;
  z-index: 99;

  h2 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .game-modes {
    margin-top: -8px;
  }

  .join-url {
      color: #000;
      font-size: 3em;
      word-break: break-word;
      &.sm {
        font-size: 2.4em;
      }
      &.xs {
        font-size: 1.8em;
      }
      &.long {
        &.xs {
          font-size: 1.4em;
        }
        &.sm {
          font-size: 1.8em;
        }
        &.md {
          font-size: 2.2em;
        }
        &.lg {
          font-size: 2.2em;
        }
        &.xl {
          font-size: 2.2em;
        }
      }
      &.very-long {
        &.xs {
          font-size: 1.2em;
        }
        &.sm {
          font-size: 1.5em;
        }
        &.md { 
          font-size: 1.8em;
        }
        &.lg {
          font-size: 2.0em;
        }
        &.xl { 
          font-size: 2.0em;
        }
      
      }
  }
  button {
      padding: 6px 20px;
      font-weight: 600;
  }
}

.room-code-container {
  margin-left: 40px;
  width: 100%;
  h3 {
    overflow: clip;
    text-overflow: ellipsis;
  }
  .room-code {
    cursor: pointer;
    position: relative;
    
    &.xs {
      font-size: 0.8em;
    }
    &.sm {
      font-size: 0.85em;
    }
    &.md {
      font-size: 0.9em;
    }

    &.long {
      &.xs {
        font-size: .65em;
      }
      &.sm {
        font-size: .75em;
      }
      &.md {
        font-size: .80em;
      }
    }
    &.very-long {
      &.xs {
        font-size: .55em;
      }
      &.sm {
        font-size: .65em;
      }
      &.md { 
        font-size: .75em;
      }
      &.lg {
        font-size: .85em;
      }
      &.xl { 
        font-size: .9em;
      }
    }
  }
  .share-icon {
    position: absolute;
    top: 10px;
    color: #000;
    margin-left: 25px;
    font-size: 1.4em;
    opacity: 0;
    transition: 300ms;
  }
  &:hover {
    color: rgba(#000, .1);
    .share-icon {
      transition-delay: 150ms;
      opacity: 1;
    }
  }
}
.phase-title {
  margin: 8px;

  &.prev, &.next {
    margin: 8px 8px -8px;
    font-size: .4em;
    vertical-align: middle;
    line-height: normal;
  }
}

// .inline-phase-title {
//   display: inline-block;
//   margin: 8px;

//   // height: 100%;
//   // line-height: 100%;
//   &.prev, &.next {
//     font-size: .5em;
//     vertical-align: middle;
//     line-height: normal;
//   }
// }

.countdown {
    transition: 300ms;
    cursor: pointer;
    .skip-icon {
        top: 10px;
        opacity: 0;
        right: 0;
        position: absolute;
        transition: 300ms;
        color: #000;
        margin-right: 80px;
        font-size: 1.2em;
    }
    &:hover {
        color: rgba(#000, .1);
        .skip-icon {
          transition-delay: 150ms;
          opacity: 1;
        }
    }
}

.remove-lr-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;

  .bomb-word-indicator-container {
    margin-right: 16px;
  }
}

.coffee-link {
  z-index: 10;
  color: #4c1e81;

  &:hover, &:active {
    color: #4c1e81;
    em {
      opacity: .8;
    }
  }

  &.higher {
    z-index: 11;
  }

  .donate {
    position: absolute;
    height: 100%;
    width: 100%;
    margin-left: -8px;
    margin-top: 0px;
    padding: 8px;

    &.attention {
      background-color: #ffbb4c;
      color: #161616;
    }
  }

  em {
    opacity: .6;
    font-size: .9em;
    font-style: italic;
  }
  
  .buy-me-coffee-img {
    height: 40px;
    width: 40px;
  }

  &.home-icon {
    padding: 0px 10px;
    vertical-align: middle;
    font-size: 1.8em;
    line-height: 1.6em;
  }
}

.new-indicator {
  margin: 0px 8px;
  background: rgba($greenColor, .7);
  padding: 4px 12px 4px 10px;
  font-style: italic;
  font-weight: bold;
  border-radius: 3px;
  color: black;
}

.attn-indicator {
  margin: 0px 8px;
  background: rgba($greyColor, .4);
  padding: 4px 12px 4px 10px;
  font-style: italic;
  font-weight: bold;
  font-size: .7em;
  border-radius: 3px;
  color: #888;
}

.no-pad {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px;
  padding-bottom: 0px;
}

@-webkit-keyframes fadeInOut {
  0% { opacity: 0; margin-left: -10px; }
  3% { opacity: 1; margin-left: 0px; }
  20% { opacity: 1; margin-left: 0px;  }
  23% { opacity: 0; margin-left: 10px; }
  100% { opacity: 0; margin-left: 10px; }
}
@-moz-keyframes fadeInOut {
  0% { opacity: 0; margin-left: -10px; }
  3% { opacity: 1; margin-left: 0px; }
  20% { opacity: 1; margin-left: 0px;  }
  23% { opacity: 0; margin-left: 10px; }
  100% { opacity: 0; margin-left: 10px; }
}
@-o-keyframes fadeInOut {
  0% { opacity: 0; margin-left: -10px; }
  3% { opacity: 1; margin-left: 0px; }
  20% { opacity: 1; margin-left: 0px;  }
  23% { opacity: 0; margin-left: 10px; }
  100% { opacity: 0; margin-left: 10px; }
}
@keyframes fadeInOut {
  0% { opacity: 0; margin-left: -10px; }
  3% { opacity: 1; margin-left: 0px; }
  20% { opacity: 1; margin-left: 0px;  }
  23% { opacity: 0; margin-left: 10px; }
  100% { opacity: 0; margin-left: 10px; }
}