$lighterDM: #c9c9c9;
$greyColorDM: #999;
$medDarkDM: #2a2a2a;
$darkDM: #1a1919;
$blueColorDM: #68a4b7;
$redColorDM: #b35050;
// $blueColorDM: #a24edf;// halloween theme turns purple
// $redColorDM: #d4701e; // halloween theme turns orange

.dark-mode-toggle-container {
  width: 100%;
  height: 100%;
  font-size: 1.4em;
  text-align: center;
  line-height: 44px;
  vertical-align: middle;
}

.theme-dark {
  body {
    background-color: $darkDM;
    color: $lighterDM;

    .background-svg-img {
      svg {
        fill: #fff;
      }
    }

    a {
      color: #b270d4;
    }

    .header {
      background-color: $medDarkDM;
      color: $lighterDM;

      a {
        color: $lighterDM;
      }
    }


    .playguide-img {
      border: 2px solid $greyColorDM;
    }

    .game-header {
      background-color: $medDarkDM;
      color: $lighterDM;

      &.red-team {
        background-color: $redColorDM;
        color: $darkDM;
        .phase-container {
          .phase-title {
            &.prev, &.next {
              color: darken($redColor, 40%);
            }
          }
        }
      }
      &.blue-team {
        background-color: $blueColorDM;
        color: $darkDM;
        .phase-container {
          .phase-title {
            &.prev, &.next {
              color: darken($blueColor, 50%);
            }
          }
        }
      }

      button {
        background: rgba($white, 0.1);
        color: $darkDM;
        &:hover {
          background-color: rgba($white, 0.2);
          color: $darkDM;
        }
      }

      .room-code {
        .share-icon {
          color: #000;
        }
        &:hover {
          color: rgba(#000, .1);
          .share-icon {
            transition-delay: 150ms;
            opacity: 1;
          }
        }
      }

      &:not(.red-team) {
        &:not(.blue-team) {
          button {
            color: $lighterDM;
            &:hover {
              background-color: rgba($lighterDM, 0.2);
              color: $lighterDM;
            }
          }

          .room-code {
            .share-icon {
              color: $lighterDM;
            }
            &:hover {
              color: rgba($lighterDM, .2);
              .share-icon {
                transition-delay: 150ms;
                opacity: 1;
              }
            }
          }
        }
      }

    }

    .new-indicator {
      background: rgba($greenColor, .7);
      color: black;
    }

    .attn-indicator {
      background: rgba($greyColor, .4);
      color: $medDarkDM;
    }


    .pages-row-item {
      &.recent-game-row {
        &:nth-child(2n+1) {
          background: rgba($greyColorDM, .1);

          .theme-name, .mode-name {
            background: darken($medDarkDM, 4%);
          }
        }
      }

      .theme-name, .mode-name {
        background: $medDarkDM;
        color: $greyColorDM;
      }

      .show-hide-sets-btn {
        background: $medDarkDM;
        border: 2px solid darken($medDarkDM, 4%);
        color: $greyColorDM;
      }
    }

    .lobby-container {
      .theme-toggle-btn {
        border: 2px solid lighten($medDarkDM, 4%);
        background: darken($medDarkDM, 8%);
        color: $lighterDM;
        
        &.available-theme {
          border: 2px solid lighten($medDarkDM, 4%);
          background: lighten($medDarkDM, 1%);
        }
    
      }
      .new-theme {
        background: rgba($greenColor, 1);
        color: $darkDM;
      }
      .theme-difficulty {
        color: $darkDM;
    
        &.easy {
          background: rgba(lightgreen, 0.5)
        }
        &.medium {
          background: rgba(yellow, 0.5)
        }
        &.hard {
          background: rgba(orange, 0.6)
        }
        &.pro {
          background: rgba(#ec3f0b, 0.6)
        }
      }
    }
    
    .footer {
      background: $medDarkDM;
      color: rgba($greyColorDM, .8);
      a {
        color: rgba($greyColorDM, .8);
      }
    }

    .game-footer {
      background-color: rgba($medDarkDM, 0.9);
      color: $greyColorDM;
      a {
        color: $greyColorDM;
      }
    }

    .coffee-link {
      .donate {
        &.attention {
          background-color: #8b5b00;
          color: #f6f6f6;
        }
      }
    }


    .instruction-block {
      background: $medDarkDM;
    }

    button {
      border: rgba($greyColorDM, 0.2) solid 2px;
      background: rgba($greyColorDM, 0.12);
      color: $lighterDM;
      &:hover {
        background-color: rgba($greyColorDM, 0.24);
      }
      &.green {
        background: rgba($greenColor, 0.4);
        &:hover {
          background-color: rgba($greenColor, 0.5);
        }
      }
      &.white {
        background: rgba($white, 0.7);
        color: $lighterDM;
        &:hover {
          background-color: rgba($white, 0.8);
        }
      }
      &.huge {
        &:disabled {
          opacity: 0.6;
          color: darken($lighterDM, 10%);
        }
      }
    }


    input, textarea {
      border-bottom: lighten($medDarkDM, 10%) 3px solid;
      color: $lighterDM;
      &:active,
      &:focus {
        border-bottom: darken($greyColorDM, 20%) 3px solid;
      }
    }


    .team-select-buttons {
      .red-team {
        border-color: rgba($redColorDM, 1);
        background: rgba($white, 0.1);

        &:hover {
          background: rgba($redColorDM, 0.4);
          border-color: $redColorDM;
        }
      }
      .blue-team {
        border-color: rgba($blueColorDM, 1);
        background: rgba($white, 0.1);

        &:hover {
          background: rgba($blueColorDM, 0.4);
          border-color: $blueColorDM;
        }
      }
    }

    .slide-in-message {
      background: $medDarkDM;
      a {
        color: $lighterDM;
      }
    }

    .game-results-container {
      .score-number {
        &:not(.for-round) {
          &.blue-team {
            background: $blueColorDM;
            color: $darkDM;
          }
          &.red-team {
            background: $redColorDM;
            color: $darkDM;
          }
        }

      }

      .teams-guess-phase-tw {
        background: $medDarkDM;
      }
    }


    .join-text {
      color: $greyColorDM;
    }

    .join-instructions-modal {
      background: $medDarkDM;
      border-radius: 12px;
      border: 6px solid rgba($darkDM, 0.1);
      color: rgba($lighterDM, .8);
      text-align: center;
      z-index: 99;

      .join-url {
          color: $lighterDM;
      }
      button {
        color: $darkDM;
      }
    }




    // word.scss
    .word-button {

      &:disabled {
          background: darken($medDarkDM, 20%);
          color: darken($lighterDM, 8%); 
      }
  
      .double-value-word-flag-container {
        .double-value-word-flag {
          
          border-left: 2px solid darken($greyColorDM, 30%);
          border-top: 2px solid darken($greyColorDM, 30%);
          border-right: 2px solid darken($greyColorDM, 30%);
          background: darken($greyColorDM, 30%);
          
          // svg {
          //   position: absolute;
          //   top: 0px;
          //   left: 0px;
          //   font-size: .7em;
          //   display: none;
          // }
          
          &:before {
            border-bottom: 2px solid darken($greyColorDM, 30%);
            border-left: 2px solid darken($greyColorDM, 30%);
            background:  darken($greyColorDM, 30%);
          }
          &:after {
            border-bottom: 2px solid darken($greyColorDM, 30%);
            border-right: 2px solid darken($greyColorDM, 30%);
            background: darken($greyColorDM, 30%);
          }
        }
      }
  
      .associated-list {
          .word {
              background: rgba($lighterDM, 0.2);
          }
      }
  
      &.red-team {
          border-color: rgba($redColorDM, 1);
          background: rgba($lighterDM, 0.08);
        
          &.active {
              background: rgba($redColorDM, 0.35);
              border-color: $redColorDM;
          }
          .counter {
              background: rgba($redColorDM, 0.2);
          }
  
          .top16 {
            fill: darken($redColorDM, 10%);
          }
          
          .double-value-word-flag {
            border-left: 2px solid $redColorDM;
            border-top: 2px solid $redColorDM;
            border-right: 2px solid $redColorDM;
            background: $redColorDM;
            &:before {
              border-bottom: 2px solid darken($redColorDM, .1);
              border-left: 2px solid darken($redColorDM, .1);
              background:  darken($redColorDM, .1);
            }
            &:after {
              border-bottom: 2px solid darken($redColorDM, .1);
              border-right: 2px solid darken($redColorDM, .1);
              background: darken($redColorDM, .1);
            }
          }
  
      }
  
      &.blue-team {
          border-color: rgba($blueColorDM, 1);
          background: rgba($lighterDM, 0.08);
  
          &.active {
              border-color: $blueColorDM;
              background: rgba($blueColorDM, 0.35);
          }
  
          .counter {
              background: rgba($blueColorDM, 0.2);
          }
  
          .top16 {
            fill: darken($blueColorDM, 10%);
          }
  
          .double-value-word-flag {
            border-left: 2px solid darken($blueColorDM, .3);
            border-top: 2px solid darken($blueColorDM, .3);
            border-right: 2px solid darken($blueColorDM, .3);
            background: darken($blueColorDM, .3);
            &:before {
              border-bottom: 2px solid darken($blueColorDM, .3);
              border-left: 2px solid darken($blueColorDM, .3);
              background:  darken($blueColorDM, .3);
            }
            &:after {
              border-bottom: 2px solid darken($blueColorDM, .3);
              border-right: 2px solid darken($blueColorDM, .3);
              background: darken($blueColorDM, .3);
            }
          }
  
      }
  
  }




  }
}