.word-button {
    padding: 8px;
    margin: 8px 0px;
    text-align: center;
    width: 100%;
    word-wrap: break-word;
    font-size: 1.4em;
    transform-box: inherit;
        
    @media (max-width: $breakpoint-phone) {
        font-size: 1.22em;
        padding: 6px;
    }
    &.skipped {
      font-style: italic;    
    } 

    &.actionable {
        // background: red
    }
    &:disabled {
        cursor: default;
        border: none !important;
        background: $greyColor;
        color: #000; 
    }
    &:hover {
      .double-value-word-flag-container {
        // opacity: .4;
      }
      
      [data-tip] { //
        z-index: 10;
      }
    }

    &.shake {
      animation: shake 0.62s cubic-bezier(.36,.07,.19,.97) both;
    }

    .word-text {
      position: relative;
      z-index: 10;

      &.not-top-16-guaranteed {
        opacity: 0.8;
      }
    }

    .bomb-word-indicator-container {
      position: absolute;
      top: 0px;
      right: 0px;
      margin-top: 18px;
      margin-right: 28px;
      opacity: 1;
      transition: 200ms;
    }


    .double-value-word-flag-container {
      position: absolute;
      top: 0px;
      left: 0px;
      margin-top: 6px;
      margin-left: 28px;
      opacity: 1;
      transition: 200ms;

      .double-value-word-flag {
        height: 20px;
        width: 14px;
        position: relative;
        border-left: 2px solid #999;
        border-top: 2px solid #999;
        border-right: 2px solid #999;
        background: #999;
        text-align: center;
        line-height: 100px;
        font-size: 30px;
        
        svg {
          position: absolute;
          top: 0px;
          left: 0px;
          font-size: .7em;
          display: none;
        }
        
        &:before {
          content: "";
          position: absolute;
          height: 50%;
          width: 50%;
          left: -2px; /*width of border*/
          bottom: -10px;
          z-index: -2;
          -webkit-transform: skewY(-20deg);
          transform: skewY(-20deg);
          border-bottom: 2px solid #999;
          border-left: 2px solid #999;
          background:  #999;
          z-index: 1;
        }
        &:after {
          content: "";
          position: absolute;
          height: 50%;
          width: 50%;
          right: -2px; /*width of border*/
          bottom: -10px;
          z-index: -2;
          -webkit-transform: skewY(20deg);
          transform: skewY(20deg);
          border-bottom: 2px solid #999;
          border-right: 2px solid #999;
          background: #999;
          z-index: 1
        }
      }
    }

    .sacrifice-indicator-container {
      width: 100%;
      font-size: 1.5em;
      .sacrifice-round-indicator {
        position: relative;
      }
    }

    .adjusted-word-indicator {
      position: absolute;
      top: 0px;
      right: 0px;
      margin-top: 22px;
      margin-right: 8px;
      opacity: 1;
      transition: 200ms;
      font-size: .8em;
    }

    .associated-list {
        margin-left: 40px !important;
        text-align: right;
        .word {
            font-size: .5em;
            padding: 3px 6px;
            background: rgba(#333, 0.2);
            border-radius: 3px;
            margin-right: 0px;
            margin-left: 8px;
        }
    }

    .counter {
        position: absolute;
        bottom: 0px;
        width: 20px;
        height: 20px;
        padding: 4px;
        font-size: 0.5em;
        font-weight: 600;
        line-height: 20px;
        border-radius: 50% 50%;

        &.counter-right {
            font-size:.7em;
            float: right;
            right: 0px;
            bottom: unset;
            position: unset;
        }
    }

    .top16 {
      position: absolute;
      top: -23px;
      right: 0px;
      width: 20px;
      height: 20px;
      margin-right: 16px;
      line-height: 20px;
      opacity: 0;
      transition: 140ms;

      &.top-16-possibility {
        top: -27px;
        right: 0px;
        width: 26px;
        height: 26px;
        margin-right: 13px;
        opacity: 0.3;
      }
      &.top-16-guaranteed { 
        opacity: 1;
        top: -31px;
        margin-right: 10px;
        width: 32px;
        height: 32px;
      }
    }

    &.red-team {
        border-color: rgba($redColor, 1);
        background: rgba($white, 0.1);
      
        &.active {
            background: rgba($redColor, 0.35);
            border-color: $redColor;
        }
        .counter {
            background: rgba($redColor, 0.2);
        }

        .top16 {
          fill: darken($redColor, 10%);
        }
        
        .double-value-word-flag {
          border-left: 2px solid $redColor;
          border-top: 2px solid $redColor;
          border-right: 2px solid $redColor;
          background: $redColor;
          &:before {
            border-bottom: 2px solid darken($redColor, .1);
            border-left: 2px solid darken($redColor, .1);
            background:  darken($redColor, .1);
          }
          &:after {
            border-bottom: 2px solid darken($redColor, .1);
            border-right: 2px solid darken($redColor, .1);
            background: darken($redColor, .1);
          }
        }

    }

    &.blue-team {
        border-color: rgba($blueColor, 1);
        background: rgba($white, 0.1);

        
        &.active {
            border-color: $blueColor;
            background: rgba($blueColor, 0.35);
        }

        .counter {
            background: rgba($blueColor, 0.2);
        }

        .top16 {
          fill: darken($blueColor, 10%);
        }

        .double-value-word-flag {
          border-left: 2px solid darken($blueColor, .3);
          border-top: 2px solid darken($blueColor, .3);
          border-right: 2px solid darken($blueColor, .3);
          background: darken($blueColor, .3);
          &:before {
            border-bottom: 2px solid darken($blueColor, .3);
            border-left: 2px solid darken($blueColor, .3);
            background:  darken($blueColor, .3);
          }
          &:after {
            border-bottom: 2px solid darken($blueColor, .3);
            border-right: 2px solid darken($blueColor, .3);
            background: darken($blueColor, .3);
          }
        }

    }

}

@keyframes shake {
  10%, 90% {
    margin-left: -1px;
  }
  
  20%, 80% {
    margin-left: 1px;
  }

  30%, 50%, 70% {
    margin-left: -2px;
  }

  40%, 60% {
    margin-left: 3px;
  }
}